import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { MODULOS } from "../../consts/modulos"
import ShowToastMessage from '../../utils/toast';
import { ALERT_TYPE } from '../../consts/alertType';
import LocalStorage from '../../context/storage/localStorage';
import { APPCONFIG } from '../../app.config';
import HeaderBasic from '../../components/layout/HeaderBasic';
import { APIS } from '../../config/apis';
import { REQUEST_METHOD } from '../../consts/requestMethodType';
import { ServerRequest } from '../../utils/apiweb';

import './index.scss';


function WelcomeView() {

    const token = LocalStorage.get("accessToken");
    const {username} = useSelector( (state) => state.auth );

    const [modulos, setModulos] = useState([])
    const [sinPermiso, setSinPermiso] = useState(false)

    useEffect(() => {
        SearchAccesses()
    }, []);

    const handleClickModulo = (value) => {
        reDirectLocation(value)
    }

    const reDirectLocation = (modulo) => {
        switch (modulo){
            case MODULOS.INGRESOS_PUBLICOS:{
                const url = APPCONFIG.WEBAPP.INGRESOS_PUBLICOS + `?username=${username}&token=${token}`;
                window.location.href = url;
                break;
            }
            case MODULOS.ADMINISTRACION:{
                const url = APPCONFIG.WEBAPP.ADMINISTRACION + `?username=${username}&token=${token}`;
                window.location.href = url;
                break;          
            }
            case MODULOS.SEGURIDAD:{
                const url = APPCONFIG.WEBAPP.SEGURIDAD + `?username=${username}&token=${token}`;
                window.location.href = url;
                break;          
            }
            case MODULOS.AUDITORIA:{
                const url = APPCONFIG.WEBAPP.AUDITORIA + `?username=${username}&token=${token}`;
                window.location.href = url;
                break;          
            }
            case MODULOS.TESORERIA:{
                const url = APPCONFIG.WEBAPP.TESORERIA + `?username=${username}&token=${token}`;
                window.location.href = url;
                break;          
            }
            default: {
                setSinPermiso(true)
            }
        }
    }

    // Callbacks
    const callbackNoSuccess = (response) => {
        response.json()
        .then((error) => {
            const message = error.message;
            ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
        })
        .catch((error) => {
            const message = 'Error procesando respuesta: ' + error;
            ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
        });
    }
    const callbackError = (error) => {
        const message = 'Error procesando solicitud: ' + error.message;
        ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
    }
    const callbackSuccess = (response) => {
        response.json()
        .then((data) => {

            const idsModulos = data.map(x => {
                return x.idModulo
            })

            // unique modulos
            const uniqueModulos = [...new Set(idsModulos)]

            if (uniqueModulos.length > 1)
                setModulos(uniqueModulos)          
            else
                reDirectLocation(uniqueModulos[0])
        })
        .catch((error) => {
            const message = 'Error procesando respuesta: ' + error;
            ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
        });
    };

    const SearchAccesses = () => {

        const paramsUrl = `/usuario`;
        
        ServerRequest(
            REQUEST_METHOD.GET,
            null,
            true,
            APIS.URLS.PERMISO,
            paramsUrl,
            null,
            callbackSuccess,
            callbackNoSuccess,
            callbackError
        );
    }

    return (
    <>  
        <HeaderBasic showMenu={false} />

        <div>
            <div className='ingreso-container'>
                <section className='ingreso-menu'>

                    <div className='row'>
                        {modulos.some(x => x === MODULOS.SEGURIDAD) &&
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ () => handleClickModulo(MODULOS.SEGURIDAD) } className="w-app nav-link">
                                <div className='ingreso-icon conf-icon' alt="configuracion"></div>
                                <span className='ingreso-text'>CONFIGURACIÓN</span>
                            </div>    
                        </div>
                        }
                        {modulos.some(x => x === MODULOS.ADMINISTRACION) &&
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ () => handleClickModulo(MODULOS.ADMINISTRACION) } className="nav-link">
                                <div className='ingreso-icon adm-icon' alt="administracion"></div>
                                <span className='ingreso-text'>ADMINISTRACIÓN</span>
                            </div>

                        </div>
                        }
                        {modulos.some(x => x === MODULOS.INGRESOS_PUBLICOS) &&
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ () => handleClickModulo(MODULOS.INGRESOS_PUBLICOS) } className="nav-link">
                                <div className='ingreso-icon ing-icon' alt="ingresos-publicos"></div>
                                <span className='ingreso-text'>INGRESOS PÚBLICOS</span>
                            </div>

                        </div>
                        }
                        {modulos.some(x => x === MODULOS.TESORERIA) &&
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ () => handleClickModulo(MODULOS.TESORERIA) } className="nav-link">
                                <div className='ingreso-icon tes-icon' alt="tesoreria"></div>
                                <span className='ingreso-text'>TESORERÍA</span>
                            </div>
                        </div>
                        }
                        {modulos.some(x => x === MODULOS.AUDITORIA) &&
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ () => handleClickModulo(MODULOS.AUDITORIA) } className="w-app nav-link">
                                <div className='ingreso-icon auditoria-icon' alt="auditoria"></div>
                                <span className='ingreso-text'>AUDITORÍA</span>
                            </div>
                        </div>
                        }
                        {sinPermiso && 
                        <div className="col-12 col-md-12 col-xl-12 ingreso">
                            <div>
                                <h2>No tiene permisos para ingresar a ningún módulo</h2>
                            </div>
                        </div>
                        }
                    </div>

                </section>   
                {/* <div className="login-container">
                    <section className='ingreso_container'>
                    </section>
                </div> */}
            </div>
        </div>
    </>
    )
    
}

export default WelcomeView;